<template>
  <div class="app-page-toolbar">
    <router-link :to="{ path: '/exam-result/new' }" v-if="hasPermissionToCreate">
      <el-button icon="el-icon-fa-plus" type="primary" :class="rtlAndPadding">
        <span class="card-title"> <app-i18n code="common.new"></app-i18n> </span>
      </el-button>
    </router-link>

    <el-tooltip
      :content="destroyButtonTooltip"
      :disabled="!destroyButtonTooltip"
      v-if="hasPermissionToDestroy"
    >
      <span>
        <el-button
          :disabled="destroyButtonDisabled"
          @click="doDestroyAllSelected"
          icon="el-icon-fa-trash"
          type="primary"
          :class="rtlAndPadding"
        >
         <span class="card-title"> <app-i18n code="common.destroy"></app-i18n> </span>
        </el-button>
      </span>
    </el-tooltip>

    <el-button
            v-if="hasAdminRole"
            @click="doAnnounceAll"
            icon="el-icon-fa-paper-plane"
            type="primary"
            :class="rtlAndPadding"
    >
     <span class="card-title"> <app-i18n code="entities.examResult.list.announceAll"></app-i18n> </span>
    </el-button>

    <el-tooltip v-if="!isStudent" :content="exportButtonTooltip" :disabled="!exportButtonTooltip">
      <span>
        <el-button
          :disabled="exportButtonDisabled"
          @click="doExport()"
          icon="el-icon-fa-file-excel-o"
          :class="rtlAndPadding"
        >
          <span class="card-title"> <app-i18n code="common.export"></app-i18n> </span>
        </el-button>
      </span>
    </el-tooltip>

    <router-link
            :to="{ path: '/audit-logs', query: { entityNames: 'examResult' } }"
            v-if="hasPermissionToAuditLogs"
    >
      <el-button icon="el-icon-fa-history" :class="rtlAndPadding">
        <span class="card-title"> <app-i18n code="auditLog.menu"></app-i18n> </span>
      </el-button>
    </router-link>
  </div>
</template>

<script>
import { AuditLogPermissions } from '@/modules/audit-log/audit-log-permissions';
import { mapGetters, mapActions } from 'vuex';
import { ExamResultPermissions } from '@/modules/exam-result/exam-result-permissions';
import { i18n } from '@/i18n';

export default {
  name: 'app-exam-result-list-toolbar',

  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
      hasRows: 'examResult/list/hasRows',
      loading: 'examResult/list/loading',
      exportLoading: 'examResult/list/exportLoading',
      selectedRows: 'examResult/list/selectedRows',
      destroyLoading: 'examResult/destroy/loading',
      isStudent: 'auth/isStudent',
      hasAdminRole: 'auth/hasAdminRole',
      rtlAndPadding: 'examResult/form/rtlAndPadding'
    }),

    hasPermissionToAuditLogs() {
      return new AuditLogPermissions(this.currentUser).read;
    },

    hasPermissionToCreate() {
      return new ExamResultPermissions(this.currentUser).create;
    },

    hasPermissionToEdit() {
      return new ExamResultPermissions(this.currentUser).edit;
    },

    hasPermissionToDestroy() {
      return new ExamResultPermissions(this.currentUser)
        .destroy;
    },

    exportButtonDisabled() {
      return (
        !this.hasRows || this.loading || this.exportLoading
      );
    },

    exportButtonTooltip() {
      return !this.hasRows
        ? i18n('common.noDataToExport')
        : null;
    },

    removeButtonDisabled() {
      return !this.selectedRows.length || this.loading;
    },

    removeButtonTooltip() {
      return !this.selectedRows.length
        ? i18n('common.mustSelectARow')
        : null;
    },

    enableButtonDisabled() {
      return !this.selectedRows.length || this.loading;
    },

    enableButtonTooltip() {
      return !this.selectedRows.length
        ? i18n('common.mustSelectARow')
        : null;
    },

    disableButtonDisabled() {
      return !this.selectedRows.length || this.loading;
    },

    disableButtonTooltip() {
      return !this.selectedRows.length
        ? i18n('common.mustSelectARow')
        : null;
    },

    destroyButtonDisabled() {
      return (
        !this.selectedRows.length ||
        this.loading ||
        this.destroyLoading
      );
    },

    destroyButtonTooltip() {
      if (!this.selectedRows.length || this.loading) {
        return i18n('common.mustSelectARow');
      }

      return null;
    },
  },

  methods: {
    ...mapActions({
      doExport: 'examResult/list/doExport',
      doRemoveAllSelected:
        'examResult/list/doRemoveAllSelected',
      doDisableAllSelected:
        'examResult/list/doDisableAllSelected',
      doEnableAllSelected:
        'examResult/list/doEnableAllSelected',
      doDestroyAll: 'examResult/destroy/doDestroyAll',
      doAnnounceAll: 'examResult/list/doAnnounceAll',
    }),

    async doDestroyAllSelected() {
      try {
        await this.$confirm(
          i18n('common.areYouSure'),
          i18n('common.confirm'),
          {
            confirmButtonText: i18n('common.yes'),
            cancelButtonText: i18n('common.no'),
            type: 'warning',
          },
        );

        return this.doDestroyAll(
          this.selectedRows.map((item) => item.id),
        );
      } catch (error) {
        // no
      }
    },
  },
};
</script>

<style></style>
