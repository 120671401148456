<template>
  <el-form
    :label-position="labelPosition"
    :label-width="labelWidthFilter"
    :model="model"
    :rules="rules"
    class="filter"
    ref="form"
    :class="checkRTL"
  >
    <el-row>
      <!-- <el-col :lg="12" :md="16" :sm="24">
        <el-form-item :label="fields.id.label" :prop="fields.id.name">
          <el-input v-model="model[fields.id.name]"/>
        </el-form-item>
      </el-col> -->

      <el-col :lg="12" :md="16" :sm="24">
        <el-form-item :label="fields.exam.label" :prop="fields.exam.name">
          <app-autocomplete-one-input
            :fetchFn="fields.exam.fetchFn"
            v-model="model[fields.exam.name]"
          ></app-autocomplete-one-input>
        </el-form-item>
      </el-col>

      <el-col :lg="12" :md="16" :sm="24" v-if="!isStudent">
        <el-form-item :label="fields.student.label" :prop="fields.student.name">
          <app-autocomplete-one-input
            :fetchFn="fields.student.fetchFn"
            v-model="model[fields.student.name]"
          ></app-autocomplete-one-input>
        </el-form-item>
      </el-col>

      <el-col :lg="12" :md="16" :sm="24">
        <el-form-item
          :label="fields.aftermathNumberRange.label"
          :prop="fields.aftermathNumberRange.name"
        >
          <app-number-range-input v-model="model[fields.aftermathNumberRange.name]" />
        </el-form-item>
      </el-col>

      <el-col :lg="12" :md="16" :sm="24">
        <el-form-item
          :label="fields.reading.label"
          :prop="fields.reading.name"
        >
          <app-number-range-input v-model="model[fields.reading.name]" />
        </el-form-item>
      </el-col>

      <el-col :lg="12" :md="16" :sm="24">
        <el-form-item
          :label="fields.speakingNumberRange.label"
          :prop="fields.speakingNumberRange.name"
        >
          <app-number-range-input v-model="model[fields.speakingNumberRange.name]" />
        </el-form-item>
      </el-col>

      <el-col :lg="12" :md="16" :sm="24">
        <el-form-item
          :label="fields.writingNumberRange.label"
          :prop="fields.writingNumberRange.name"
        >
          <app-number-range-input v-model="model[fields.writingNumberRange.name]" />
        </el-form-item>
      </el-col>

      <el-col :lg="12" :md="16" :sm="24">
        <el-form-item
          :label="fields.listeningNumberRange.label"
          :prop="fields.listeningNumberRange.name"
        >
          <app-number-range-input v-model="model[fields.listeningNumberRange.name]" />
        </el-form-item>
      </el-col>

      <!--      <el-col :lg="12" :md="16" :sm="24">-->
      <!--        <el-form-item-->
      <!--                :label="fields.resultStatus.label"-->
      <!--                :prop="fields.resultStatus.name"-->
      <!--        >-->
      <!--          <el-select-->
      <!--                  placeholder-->
      <!--                  v-model="model[fields.resultStatus.name]"-->
      <!--          >-->
      <!--            <el-option :value="undefined">&#45;&#45;</el-option>-->
      <!--            <el-option-->
      <!--                    :key="option.id"-->
      <!--                    :label="option.label"-->
      <!--                    :value="option.id"-->
      <!--                    v-for="option in fields.resultStatus.options"-->
      <!--            ></el-option>-->
      <!--          </el-select>-->
      <!--        </el-form-item>-->
      <!--      </el-col>-->
    </el-row>

    <div class="filter-buttons">
      <el-button
        :disabled="loading"
        @click="doFilter"
        icon="el-icon-fa-search"
        type="primary"
        native-type="submit"
        :class="rtlAndPadding"
      >
       <span class="card-title"> <app-i18n code="common.search"></app-i18n> </span>
      </el-button>

      <el-button
        :disabled="loading"
        @click="doResetFilter"
        icon="el-icon-fa-undo"
        :class="rtlAndPadding"
      >
       <span class="card-title"> <app-i18n code="common.reset"></app-i18n> </span>
      </el-button>
    </div>
  </el-form>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { FilterSchema } from '@/shared/form/filter-schema';
import { ExamResultModel } from '@/modules/exam-result/exam-result-model';

const { fields } = ExamResultModel;

const filterSchema = new FilterSchema([
  //fields.id,
  fields.exam,
  fields.student,
  fields.aftermath,
  fields.reading,
  fields.listening,
  fields.writing,
  fields.speaking,
  //fields.resultStatus
]);

export default {
  name: 'app-exam-result-list-filter',

  data() {
    return {
      rules: filterSchema.rules(),
      model: {},
    };
  },

  computed: {
    ...mapGetters({
      labelPosition: 'layout/labelPosition',
      labelWidthFilter: 'layout/labelWidthFilter',
      loading: 'examResult/list/loading',
      filter: 'examResult/list/filter',
      isStudent: 'auth/isStudent',
      checkRTL: 'examResult/form/checkRTL',
      rtlAndPadding: 'examResult/form/rtlAndPadding'
    }),

    fields() {
      return fields;
    },
  },

  async mounted() {
    this.model = filterSchema.initialValues(
      this.filter,
      this.$route.query,
    );

    return this.doFilter();
  },

  methods: {
    ...mapActions({
      doReset: 'examResult/list/doReset',
      doFetch: 'examResult/list/doFetch',
    }),

    async doResetFilter() {
      this.model = filterSchema.initialValues();
      this.$refs.form.clearValidate();
      return this.doReset();
    },

    async doFilter() {
      try {
        await this.$refs.form.validate();
        this.$refs.form.clearValidate();
      } catch (error) {
        return;
      }

      const filter = filterSchema.cast(this.model);
      return this.doFetch({
        filter,
      });
    },
  },
};
</script>

<style></style>
