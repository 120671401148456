<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">
        <app-i18n code="home.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <app-i18n
          code="entities.examResult.menu"
        ></app-i18n>
      </el-breadcrumb-item>
    </el-breadcrumb>

    <div class="app-content-page">
      <h1 class="app-content-title">
        <app-i18n
          code="entities.examResult.list.title"
        ></app-i18n>
      </h1>

      <app-exam-result-list-toolbar></app-exam-result-list-toolbar>
      <app-exam-result-list-filter></app-exam-result-list-filter>
      <app-exam-result-list-table></app-exam-result-list-table>
    </div>
  </div>
</template>

<script>
import ExamResultListFilter from '@/modules/exam-result/components/exam-result-list-filter.vue';
import ExamResultListTable from '@/modules/exam-result/components/exam-result-list-table.vue';
import ExamResultListToolbar from '@/modules/exam-result/components/exam-result-list-toolbar.vue';

export default {
  name: 'app-exam-result-list-page',

  components: {
    [ExamResultListFilter.name]: ExamResultListFilter,
    [ExamResultListTable.name]: ExamResultListTable,
    [ExamResultListToolbar.name]: ExamResultListToolbar,
  },
};
</script>

<style></style>
