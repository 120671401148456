<template>
  <div>
    <el-table stripe
      :border="true"
      :data="rows"
      @sort-change="doChangeSort"
      ref="table"
      row-key="id"
      v-loading="loading"
    >
      <el-table-column align="center" type="selection" width="40"></el-table-column>

      <!--      <el-table-column :label="fields.id.label" :prop="fields.id.name" sortable="custom">-->
      <!--        <template slot-scope="scope">{{ presenter(scope.row, 'id') }}</template>-->
      <!--      </el-table-column>-->

      <el-table-column align="center" v-if="!isStudent" :label="fields.student.label" :prop="fields.student.name">
        <template slot-scope="scope">
          <el-popover trigger="hover" placement="top">
            <p>{{ getStudent(scope.row).email }}</p>
            <div slot="reference" class="name-wrapper">
              <app-list-item-relation-to-one
                      :label="fields.student.label"
                      :permission="fields.student.readPermission"
                      :url="fields.student.viewUrl"
                      :value="presenter(scope.row, 'student')"
              ></app-list-item-relation-to-one>
            </div>
          </el-popover>
        </template>
      </el-table-column>

      <el-table-column align="center" :label="fields.exam.label" :prop="fields.exam.name">
        <template slot-scope="scope">
          <app-list-item-relation-to-one
            :label="fields.exam.label"
            :permission="fields.exam.readPermission"
            :url="fields.exam.viewUrl"
            :value="presenter(scope.row, 'exam')"
          ></app-list-item-relation-to-one>
        </template>
      </el-table-column>

      <el-table-column align="center"
              :label="fields.aftermath.label"
              :prop="fields.aftermath.name"
              sortable="custom"
              width="90"
      >
        <template slot-scope="scope">{{ presenter(scope.row, 'aftermath') }}</template>
      </el-table-column>

      <el-table-column align="center"
              :label="fields.reading.label"
              :prop="fields.reading.name"
              sortable="custom"
              width="90"
      >
        <template slot-scope="scope">{{ presenter(scope.row, 'reading') }}</template>
      </el-table-column>

      <el-table-column align="center"
              :label="fields.writing.label"
              :prop="fields.writing.name"
              sortable="custom"
              width="90"
      >
        <template slot-scope="scope">{{ presenter(scope.row, 'writing') }}</template>
      </el-table-column>

      <el-table-column align="center"
              :label="fields.listening.label"
              :prop="fields.listening.name"
              sortable="custom"
              width="90"
      >
        <template slot-scope="scope">{{ presenter(scope.row, 'listening') }}</template>
      </el-table-column>

      <el-table-column align="center"
              :label="fields.speaking.label"
              :prop="fields.speaking.name"
              sortable="custom"
              width="90"
      >
        <template slot-scope="scope">{{ presenter(scope.row, 'speaking') }}</template>
      </el-table-column>

      <el-table-column align="center" :fixed="isMobile? undefined : 'right'" width="180">
        <template slot-scope="scope">
          <div class="table-actions">
            <router-link :to="`/score-report/${scope.row.id || ''}`">
              <el-button type="text">
                <app-i18n code="common.scoreReport"></app-i18n>
              </el-button>
            </router-link>

            <router-link :to="`/exam-result/${scope.row.id || ''}`">
              <el-button type="text">
                <app-i18n code="common.view"></app-i18n>
              </el-button>
            </router-link>

            <router-link :to="`/exam-result/${scope.row.id || ''}/edit`" v-if="hasPermissionToEdit">
              <el-button type="text">
                <app-i18n code="common.edit"></app-i18n>
              </el-button>
            </router-link>

            <router-link v-if="showAnalyzer(scope.row)" :to="`/exam-analysis/${getExam(scope.row).id}/${getStudent(scope.row).id}`">
              <el-button type="text">
                <app-i18n code="common.analyze"></app-i18n>
              </el-button>
            </router-link>

            <el-button
              :disabled="destroyLoading"
              @click="doDestroyWithConfirm(scope.row.id || '')"
              type="text"
              v-if="hasPermissionToDestroy"
            >
              <app-i18n code="common.destroy"></app-i18n>
            </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <div class="el-pagination-wrapper">
      <el-pagination
        :current-page="pagination.currentPage || 1"
        :disabled="loading"
        :layout="paginationLayout"
        :total="count"
        @current-change="doChangePaginationCurrentPage"
        @size-change="doChangePaginationPageSize"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { ExamResultModel } from '@/modules/exam-result/exam-result-model';
import { mapGetters, mapActions } from 'vuex';
import { ExamResultPermissions } from '@/modules/exam-result/exam-result-permissions';
import { i18n } from '@/i18n';

const { fields } = ExamResultModel;

export default {
  name: 'app-exam-result-list-table',

  mounted() {
    this.doMountTable(this.$refs.table);
  },

  computed: {
    ...mapGetters({
      rows: 'examResult/list/rows',
      count: 'examResult/list/count',
      loading: 'examResult/list/loading',
      pagination: 'examResult/list/pagination',
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
      isStudent: 'auth/isStudent',
      destroyLoading: 'examResult/destroy/loading',
      paginationLayout: 'layout/paginationLayout',
      hasAdminRole: 'auth/hasAdminRole',
    }),

    hasPermissionToEdit() {
      return new ExamResultPermissions(this.currentUser).edit;
    },

    hasPermissionToDestroy() {
      return new ExamResultPermissions(this.currentUser).destroy;
    },

    fields() {
      return fields;
    },
  },

  methods: {
    ...mapActions({
      doChangeSort: 'examResult/list/doChangeSort',
      doChangePaginationCurrentPage:
        'examResult/list/doChangePaginationCurrentPage',
      doChangePaginationPageSize:
        'examResult/list/doChangePaginationPageSize',
      doMountTable: 'examResult/list/doMountTable',
      doDestroy: 'examResult/destroy/doDestroy',
    }),

    getStudent(row){
      if(row.student){
        return row.student;
      }
      return {
        email: '-',
        id: row.studentId
      };
    },

    getExam(row){
      if(row.exam){
        return row.exam;
      }
      return {
        id: ''
      };
    },

    presenter(row, fieldName) {
      return ExamResultModel.presenter(row, fieldName);
    },

    showAnalyzer(row){
      const isPaidAndFixed = row.exam.fixQuestions && !row.exam.isFree;
      return isPaidAndFixed || this.hasAdminRole;
    },
    async doDestroyWithConfirm(id) {
      try {
        await this.$confirm(
          i18n('common.areYouSure'),
          i18n('common.confirm'),
          {
            confirmButtonText: i18n('common.yes'),
            cancelButtonText: i18n('common.no'),
            type: 'warning',
          },
        );

        return this.doDestroy(id);
      } catch (error) {
        // no
      }
    },
  },
};
</script>

<style></style>
